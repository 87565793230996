/* Navbar.css */
:root {
  --background-color-dark: #222222;
  --text-color-dark: white;
  --hover-color-dark: #00BFFF;
  --active-color-dark: #009DCF;

  --background-color-light: #FFFFFF;
  --text-color-light: #000000;
  --hover-color-light: #0077FF;
  --active-color-light: #0055CC;
}

.navbar {
  position: fixed;
  left: 0;
  top: 0;
  width: 250px;
  height: 100%;
  background-color: var(--background-color-dark);
  transition: width 0.3s;
}

.navbar ul {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  list-style-type: none;
  padding: 0;
  font-size: 20px;
  height: 60%;
}

.navbar li {
  position: relative;
  margin-bottom: 30px;
}

.navbar li::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100px;
  height: 1px;
  background-color: var(--text-color-dark);
}


.navbar a {
  display: flex;
  align-items: center;
  padding: 10px;
  text-decoration: none;
  color: var(--text-color-dark);
  white-space: nowrap;
  overflow: hidden;
  margin-bottom: 10px;
}

.navbar a span {
  margin-left: 10px;
}

.navbar a:hover {
  color: var(--hover-color-dark);
}

.navbar .active a {
  color: var(--active-color-dark);
}

.navbar svg {
  margin-right: 10px;
}

.navbar .Footer {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  text-align: center;
  color: var(--text-color-dark);
  font-size: 12px; 
}

.navbar .logo-container {
  text-align: center;
  margin-bottom: 50px;
}

.navbar nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.navbar .logo {
  width: 80%;
  top: 1px;
}

/* Light mode styles */
@media (prefers-color-scheme: light) {
  .navbar {
    background-color: var(--background-color-light);
  }

  .navbar a {
    color: var(--text-color-light);
  }

  .navbar .Footer {
    color: var(--text-color-light);
  }

  .navbar li::after {
    background-color: var(--text-color-light);
  }

  .navbar a:hover {
    color: var(--hover-color-light);
  }

  .navbar .active a {
    color: var(--active-color-light);
  }
}

/* Responsive Styles */
@media (max-width: 768px) {
  .navbar {
    width: 70px;
  }

  .navbar a span {
    text-indent: -9999px;
    margin-left: 3px;
    margin-bottom: 0;
  }

  .navbar a {
    margin-bottom: 0;
  }

  .navbar svg {
    margin: 30px 30px;
    font-size: 22px;
  }

  .navbar .logo svg {
    width: 500px;
    height: 500px;
  }

  .navbar .logo-container {
    object-fit: fill;
    margin-bottom: 50px;
  }

  .navbar ul {
    font-size: 13px;
  }

  .navbar a {
    padding: 0;
  }

  .navbar li {
    margin-bottom: 10px;
  }

  .navbar li::after {
    width: 50%;
  }
}

@media (max-width: 480px) {
  .Footer p {
    font-size: 10px;
  }
}
