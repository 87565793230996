/* Projets.css */
.projets hr {
  margin-bottom: 40px;
}

.project-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  opacity: 0;
  transform: translateY(650px);
  transition: opacity 0.2s, transform 0.6s;
}

.project-list.loaded {
  opacity: 1;
  transform: translateY(0);
  margin-bottom: 30px;
}

.project-item {
  max-width: 320px;
  width: 120%;
  height: 250px;
  border: 3px solid var(--color-primary);
  margin-bottom: 80px;
  margin-right: 30px;
  transition: transform 0.6s ease;
  color: var(--color-text);
  background-color: var(--color-background);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  p {
    font-size: 20px;
    text-align: center;
  }

  &:hover {
    transform: scale(1.07);
  }
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.9);
  backdrop-filter: blur(10px);
  z-index: 1000;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  overflow-y: auto;
  max-width: 90%;
  max-height: 90%;
  color: var(--color-text);
}

.modal-content {
  width: 100%;
  height: auto;
  max-height: calc(100vh - 100px);
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;
  color: var(--color-text);

  h1, h3, p {
    text-align: center;
  }

  p {
    color: var(--color-text);
    margin-bottom: 40px;
  }

  a {
    text-decoration: none;
    color: var(--color-primary);
  }
}

.close {
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;
  font-size: 40px;
  color: var(--color-text);
}

@media (prefers-color-scheme: light) {
  .modal {
    background-color: rgba(255, 255, 255, 0.9);
    color: #000;
  }

  .close {
    color: #000;
  }

  .modal-content p {
    color: #000;
  }

  .modal-content a {
    color: var(--color-primary);
  }
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .project-item {
    width: 40%;
    height: auto;

    & img {
      object-fit: cover;
    }
  }

  .modal {
    width: 70%;
  }
}

@media (max-width: 768px) {
  .project-item {
    width: 40%;
    height: auto;
    margin-right: 10px;
  }

  .modal {
    font-size: 14px;
    width: 65%;
  }

  .close {
    font-size: 30px;
  }
}

@media (max-width: 480px) {
  .project-item {
    width: 40%;
    height: auto;
    margin-right: 10px;
  }

  .modal-content {
    padding: 10px;
  }

  .close {
    font-size: 30px;
  }

  p {
    font-size: 16px;
  }
}
