.Skills {
  color: var(--color-text);
  text-align: center;
}

.skills-container {
  margin: 0 auto;
  max-width: 1200px;
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.6s, transform 0.6s;
}

.skills-container.loaded {
  opacity: 1;
  transform: translateY(0);
}

.skills-columns {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.column {
  flex: 1;
  padding: 20px;
  margin: 10px 80px;
  min-width: 300px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.skills-container.loaded .column:nth-child(1) {
  opacity: 1;
  transition-delay: 0.3s;
}

.skills-container.loaded .column:nth-child(2) {
  opacity: 1;
  transition-delay: 0.6s;
}

.column h3 {
  font-size: 29px;
  margin-bottom: 20px;
}

.icons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.skill {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  transition: transform 0.3s, box-shadow 0.3s;
}

.skill:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.icons span {
  font-size: 16px;
  margin-top: 10px;
}

.skill-icon, .image-icon {
  font-size: 80px;
  color: var(--color-primary);
  padding: 20px;
  width: 80px;
}

@media (max-width: 1024px) {
  .skills-columns {
    flex-direction: column;
    margin-bottom: 30px;
  }
}

@media (max-width: 768px) {
  .Skills h1 {
    font-size: 32px;
  }

  .column {
    margin: 5px 0;
  }

  .icons span {
    font-size: 14px;
  }

  .skill-icon, .image-icon {
    font-size: 88px;
  }
}

@media (max-width: 425px) {
  .Skills h1 {
    font-size: 24px;
  }

  .icons span {
    font-size: 13px;
    margin-top: 5px;
  }

  .skill-icon, .image-icon {
    font-size: 40px;
  }

  .icons {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}
