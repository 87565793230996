/* Contact.css */
.Contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  color: var(--color-text);
}

.Contact h1 {
  margin-bottom: 0;
}

.Contact p {
  max-width: 800px;
  text-align: center;
  margin-bottom: 50px;
  font-size: 18px;
}

input[type='text'],
input[type='email'],
textarea {
  width: 90%;
  padding: 15px;
  margin-bottom: 20px;
  border: 2px solid var(--color-border);
  border-radius: 10px;
  font-size: 16px;
  background-color: var(--color-background);
  color: var(--color-text);
  transition: border-color 0.1s ease;
}

input[type='text']:focus,
input[type='email']:focus,
textarea:focus {
  border-color: var(--color-border-focus);
  outline: none;
}

textarea {
  height: 150px;
}

.submit-button {
  background-color: var(--color-border-focus);
  color: var(--color-text);
  padding: 15px 30px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.submit-button:hover {
  background-color: var(--color-primary-hover);
  transform: scale(1.05);
}


@media (max-width: 1024px) {
  .Contact {
    width: 75%;
  }

  .Contact p {
    margin-bottom: 20px;
  }
}
