/* Home.css */
.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: inherit;
  margin-left: 250px;
  text-align: center;
  opacity: 0;
  animation: fade-in 1s forwards 0.3s;
}

@keyframes fade-in {
  to {
    opacity: 1;
  }
}

.home h1,
.home h2 {
  opacity: 0;
  transform: translateY(20px);
  animation: slide-up 0.3s forwards;
}

.home h1 {
  animation-delay: 0.6s;
}

.home h2 {
  animation-delay: 0.8s;
}

@keyframes slide-up {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.social-links {
  margin: 20px;
  font-size: 30px;
  opacity: 0;
  animation: fade-in 0.5s forwards 1s;
}

.social-links a {
  color: var(--color-border-focus);
  margin: 0 10px;
}

.social-links button {
  background-color: transparent;
  border: none;
  cursor: pointer; 
  font-size: 30px;
  color: var(--color-border-focus);
}

.social-links a:hover,
.social-links button:hover {
  color: var(--color-primary-hover);
}

hr {
  width: 20%;
}

.discord-pseudo {
  animation: slide-in 0.5s forwards;
  display: inline-block;
  margin-left: 10px;
  font-size: 24px;
}


@keyframes slide-in {
  0% {
    transform: translateX(-50%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Responsive Styles */
@media (max-width: 768px) {
  .home {
    margin-left: 70px;
    padding: 5px;
  }
  .home h2 {
    font-size: 21px;
  }
}
