@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&family=JetBrains+Mono:ital,wght@0,100..800;1,100..800&display=swap');

:root {
  --color-background: #f0f0f0;
  --color-text: #000;
  --color-primary: #009DCF;
  --color-primary-hover: #007BA7;
  --color-border: #009DCF;
  --color-border-focus: #0077B6;
  --separator-color: #000;
}

@media (prefers-color-scheme: dark) {
  :root {
    --color-background: #151515;
    --color-text: #fff;
    --separator-color: #fff;
  }
}

body {
  margin: 0;
  font-family: "Fira Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-background);
  color: var(--color-text);
}

h1 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 35px;
}

.sections {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  margin-left: 250px;
}

.separator {
  height: 2px;
  margin: 20px auto;
  width: 300px;
  display: block;
  background-color: var(--separator-color);
}

@media screen and (max-width: 768px) {
  .sections {
    margin-left: 80px;
  }

  .separator {
    max-width: 300px;
    width: 70%;
  }
}
